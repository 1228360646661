@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');





/* reset */

*{
    margin: 0;
    padding: 0;
    letter-spacing: -0.5px;
    box-sizing: border-box;
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
    font-size: 62.5%;
    /* 위에 진짜 왜 그런지... */
    color: #101010;
    /*-webkit-touch-callout: none;*/
    /*user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*-webkit-user-select: none;*/
}

html, body{
    width: 100%;
    height: 100%;
}

table{
    table-layout: fixed;
}

ul, li{
    list-style: none;
}

a{
    text-decoration: none;
    cursor: pointer;
}
img{
    border: 0;
}

table{
    table-layout: fixed;
    border-collapse: collapse;
}

body::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}

body::-webkit-scrollbar-thumb{
    background-color: #DADADA;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}

body::-webkit-scrollbar-track{
    border-radius: 10px;
}

details summary::-webkit-details-marker{
    display: none;
}


/* .gnb-menu li:first-child a{color: #F16722; font-weight: 700;}
.hamburger nav ul li:first-child label{color: #F16722; font-weight: 700;}
.main-bg{background: url('/public/images/bg-02.png') no-repeat center/cover;}
.h3 span{color: #F16722; font-size: 5rem;} */

/* pagination */
.pagination {
    display: flex;
    margin-top:10px;
    justify-content: center;
}

.pagination li {
    border: 1px solid #dee2e6;;
    width: 25px;
    height: 25px;
}

.pagination li a {
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
}

.pagination li:not(:global(.selected)):not(:global(.disabled)):hover {
    background-color: rgba(241,103,34,0.5);
    border-color: rgba(241,103,34,0.5);
}

.pagination :global(.previous) {
    margin:0 10px;
}

.pagination :global(.next) {
    margin:0 10px;
}

.pagination :global(.next) {
    margin:0 10px;
}


.pagination :global(.disabled) a:hover {
    cursor: unset;
}
.pagination :global(.disabled) a {
    opacity: 0.5;
}

.pagination :global(.selected) {
    background-color: #F16722;
    border-color: #F16722;
    color: white;
}
.pagination :global(.selected) a {
    color: white;
}

/* reply */
.reply {
    margin-top: 20px;
}

.reply h3 {
    font-size: 20px;
}

.reply p {
    text-align: center;
}

.reply > div > div {
    border-bottom: 1px solid #dee2e6;
}

.create_post_button {
    width: 100%;
    height: 30px;
    background-color: #F16722;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    border: none;
    transition: .3s;
    font-size: 15px;
    padding: 0px 10px;
}
.create_post_button:hover, .create_post_button:active{
    box-shadow: inset 0 4em 0 0 #F16722;
}


.kakaoBtn {
    /* position: absolute;
    top:100px;
    right:100px; */
    position: relative;
    overflow: hidden;
    top:2px;
    width: 33px;
}

.kakaoBtn img {
    width: 100%;
}

.youtubeBtn {
    /* position: absolute;
    top:100px;
    right:100px; */
    width: 33px;
}

.youtubeBtn img {
    width: 100%;
}


.iconFlexBox {
    display: flex;
    height: 40px;
    align-items: center;
    gap: 4px;
}
.iconFlexBox img,
.iconFlexBox svg {
    width: 24px;
    height: 24px;
}
.iconFlexBox a {
    display: contents;
}
.iconFlexBox > div:last-child {
    margin-left: 32px;
}


.wrap{
    overflow: hidden;
    width: 100%;
}

.main_visual{
    width: 100%;
    background: #000;
}

.header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: 100;
}

.header_overlay{
    position: absolute;
    width: 100%;
    height: 230px;
    background: linear-gradient(#000, transparent);
}

.header_in{
    position: relative;
    top: 0;
    left: 0;
    width: 90vw;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin: 0 auto;
}

.gnb_logo{
    margin-right: 4vw;
    z-index: 1000;
}

.gnb_menu{
    display: flex;
    white-space: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 120px;
}

.gnb_menu li{
    position: relative;
    padding: 2rem;
}

.gnb_menu li:after{
	content: "";
	position: absolute;
	width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: #F16722;
    top: 25%;
    left: 49%;
    opacity: 0;
}

.gnb_menu li > a{
    display: block;
    color: #fff;
    font-size: 2.4rem;
    font-weight: 500;
    text-align: center;
    padding: 2rem;
}

.gnb_menu li a:hover{
    font-weight: 700;
    color: #F16722;
    transition: .5s;
}

.gnb_menu li:hover:after{
    opacity: 1;
	-webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.gnb_menu li:hover > .panel {
    display: flex;
    transform: scaleY(1);
}

.panel {
    transition: 0.2s;
    transform: scaleY(0);
    transform-origin: top;
    left: 0;
    padding: 10px;
    display: flex;
    position: absolute;
    width:100%;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
}

.panel:before {
    position: absolute;
    transform-origin: top;
    content: "";
    left: 45%;
    top: -20px;
    width:0px;
    height:0px;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    border-bottom: 10px solid white;
    border-left: 10px solid rgba(0, 0, 0, 0);
}

.panel a {
    font-size: 15px;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}
.panel a:last-child {
    border-bottom: 0px solid #e0e0e0;
}





.gnb_btn{
    display: flex;
    gap: 10px;
    align-items: center;
}

.hamburger{
    display: none;
    z-index: 99999;
}

.gnb_login{
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50px;
    letter-spacing: 0;
    color: #fff;
    font-size: 1.2rem;
}





.video_wrap{
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    animation: fadeIn ease 8s;
    -webkit-animation: fadeIn ease 8s;
    -moz-animation: fadeIn ease 8s;
    -o-animation: fadeIn ease 8s;
    -ms-animation: fadeIn ease 8s;
}

.video_wrap video{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    min-width: 100%;
    height: 100vh;
    object-fit: cover;
}





.h1{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 0;
    color: #fff;
    font-size: 8rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    animation: fadeIn ease 8s;
    -webkit-animation: fadeIn ease 8s;
    -moz-animation: fadeIn ease 8s;
    -o-animation: fadeIn ease 8s;
    -ms-animation: fadeIn ease 8s;
}

.h1 span{
    color: #DF1717;
    font-size: 8rem;
    font-weight: 700;
    letter-spacing: 0;
}





.mainBtn_wrap{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
}

.mainBtn_wrap a{
    width: 426.67px;
    height: 136px;
    background-color: rgb(255, 255, 255, 0.1);
    padding: 20px;
    position: relative;
    top: 0;
    left: 0;
    animation: fadeIn ease 8s;
    -webkit-animation: fadeIn ease 8s;
    -moz-animation: fadeIn ease 8s;
    -o-animation: fadeIn ease 8s;
    -ms-animation: fadeIn ease 8s;
}

.mainBtn_wrap a{
    border-right: 1px solid #808080;
}

.mainBtn_wrap a:last-child{
    border-right: none;
}

.mainBtn_i{
    position: absolute;
    top: 20px;
    left: 20px;
}

.mainBtn_i_h{
    position: absolute;
    top: 20px;
    left: 20px;
    display: none;
}

.mainBtn_wrap a:hover .mainBtn_i{
    display: none;
}

.mainBtn_wrap a:hover .mainBtn_i_h{
    display: block;
}

.mainBtn_line2{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 386.67px;
}

.mainBtn_txt{
    color: #fff;
    font-size: 2.4rem;
    font-weight: 700;
    white-space: nowrap;
}

.mainBtn_line2 img{
    margin-top: 4px;
    -webkit-transition: all .3s ease-in-out;
}

.mainBtn_wrap a:hover{
    background-color: #F16722;
    transition: .6s ease-out;
}

.mainBtn_wrap a:hover img{
    -webkit-transition: all .6s ease-in-out;
}

.mainBtn_wrap a:hover .mainBtn_ar1{
    /*transform : translateX(198px);*/
}

.mainBtn_wrap a:hover .mainBtn_ar2{
    /*transform : translateX(225px);*/
}

.mainBtn_wrap a:hover .mainBtn_ar3{
    /*transform : translateX(175px);*/
}




.footer{
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100px;
    width: 100%;
}

.f_1{
    display: flex;
    align-items: center;
}

.f_1 img{
    width: 150px;
    height: 30.17px;
}

.footer span{
    font-weight: 700;
    font-size: 1.2rem;
}

.f_1 div{
    margin-left: 20px;
    font-size: 1.2rem;
    word-break: keep-all;
}

.f_1 div:last-child, .f_2 div:last-child{
    letter-spacing: 0;
}

.f_2{
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 10px;
    word-break: keep-all;
}

.f_2 div, .f_2 div a{
    font-size: 1.2rem;
}

.f_2 > div img{
    vertical-align: -3px;
    margin-left: 5px;
}
.tel {
    font-size: 16px !important;
    margin-left: 5px;
}





.main_bg{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
    -webkit-animation: kenburns-top 2s ease-out both;
    animation: kenburns-top 2s ease-out both;
}

.h2{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 0;
    max-width: 100%;
}

.h2 div{
    color: #fff;
    font-size: 8rem;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.h2 div *{
    -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.h2 div:nth-child(2){
    color: #fff;
    font-size: 2.4rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
}





.scroll_downs{
    position: absolute;
    right: 0;
    bottom: 5.2vh;
    left: 0;
    margin: auto;
    width : 34px;
    height: 55px;
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
}

.mousey{
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller{
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
}





.subnav_btn{
    position: absolute;
    bottom: -150px;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    width: 67vw;
    height: 80px;
    margin: 0 auto;
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

.subnav_btn div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.666%;
    background-color: #aaaaaa;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    padding: 2px;
}

.subnav_btn div a{
    color: #fff;
    font-size: 2.4rem;
    text-align: center;
    display: block;
}

/* .subnav_btn div a:hover, .subnav_btn div a:active {
    font-weight: 700;
    box-shadow: inset 0 3.5em 0 0 #F16722;
    transition: .3s;
}

.subnav_btn_full div a:hover, .subnav_btn_full div a:active {
    text-shadow: 0 -1px 1px #fff;
    box-shadow: inset 0 5em 0 0 #F16722;
    transition: .3s;
} */



.subnav_btn_full{
    position: absolute;
    bottom: -150px;
    left: 50%;
    /* -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%); */
    display: flex;
    justify-content: center;
    width: 86vw;
    height: 80px;
    line-height: 80px;
    margin: 0 auto;
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;


}

.subnav_btn_full div{
    position: relative;
    flex-basis: 25%;
}
.subnav_btn_full div a{
    color: #fff;
    font-size: 2rem;
    text-align: center;
    display: block;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    width: 100%;
}

.subnav_btn_full div:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top:0px;
    transform: scaleY(0);
    transform-origin: top;
    background-color: #F16722;
}

.subnav_btn_full div:hover:before {
    transition: .3s;
    transform: scaleY(1);
}

.subnav_btn div{
    position: relative;
}

.subnav_btn div a{
    position: relative;
    z-index: 1;
}

.subnav_btn div:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top:0px;
    transform: scaleY(0);
    transform-origin: top;
    background-color: #F16722;
}

.subnav_btn div:hover:before {
    transition: .3s;
    transform: scaleY(1);
}




.main_container{
    margin: 0 auto;
}

.h3{
    font-size: 5rem;
    font-weight: 900;
    text-align: center;
    margin-top: 200px;
    margin-bottom: 50px;
    /* margin-bottom: 9.3vh; */
}



.inquiry_subtitle{
    font-size: 2rem;
    color:gray;
    font-weight: 700;
    text-align: center;
    word-break: keep-all;
    margin: 15px 0;
}

.tel_number {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.tel_number img {
    max-width: 60px;
}

.tel_number a {
    font-size: 3rem;
}


.process_wrap{
    width: 100%;
}

.process_1{
    width: 100%;
    height: 457px;
    background-color: #f8f8f8;
    margin-bottom: 50px;
}

.process_1 div{
    margin: 0 auto;
    max-width: 67vw;
    height: 100%;
    background: url('/public/images/04-01.png') no-repeat;
    background-size: 100%;
    background-position: center;
}

.process_2{
    margin: 0 auto;
    padding: 20px;
    max-width: 67vw;
    min-height: 220px;
    border: 10px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 27.8vh;
    word-break: keep-all;
}

.process_2 ul li{
    color: #505050;
    font-size: 1.6rem;
}

.txt_span{
    color: #F16722;
    font-size: 1.6rem;
}




.top_btn{
    position: fixed;
    bottom: -80px;
    right: 30px;
    display: none;
}

.top_btn.roll{
    display: block;
    -webkit-animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}







.contact_wrap{
    width: 67vw;
    margin: 0 auto;
    margin-bottom: 9.3vh;
}
.contact_wrap input, .contact_wrap textarea {
    font-size: 1.6rem;
    padding-left: 2rem;
}
.contact_form > select > option {
    font-size: 1.6rem;
}
.contact_form, .contact_form_2{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.contact_form p {
    padding-left: 15px;
    font-size: 1.1rem;
}

.contact_form_2 > input{
    width: 32.5vw;
}

.contact_form_2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact_form_2 div{
    width: 33vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact_form_2 div input{
    width: 24vw;
}

.contact_form_2 div button{
    width: 8vw;
    height: 60px;
    background-color: #aaaaaa;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
}

.contact_form input, .contact_form_2 input, select{
    width: 33vw;
    height: 60px;
    background-color: #f5f5f5;
    border: none;
}

input::placeholder, select{
    color: #aaaaaa;
    padding-left: 20px;
    font-size: 1.6rem;
}

select{
    padding-left: 2rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(/public/images/select.png) no-repeat #f5f5f5;
    background-position: calc(100% - 10px) center;
}

select::-ms-expand{
    display: none;
}

.sel_pl{
    background-color: #f5f5f5;
    color: #f5f5f5;
}

textarea{
    width: 100%;
    height: 300%;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-top: 20px;
    border: none;
    background-color: #f5f5f5;
}

textarea::placeholder{
    font-size: 1.6rem;
}

input[type="checkbox"]{
    display: none;
}

input[type="checkbox"] + label i{
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: -4px;
    background: url('/public/images/check-x.png') no-repeat center/cover;
    cursor: pointer;
}

input[type="checkbox"]:checked + label i{
    background: url('/public/images/check-o.png') no-repeat center/cover;
}

.contact_form_c{
    width: 300px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;
}

.contact_form_c span{
    margin-left: 10px;
    font-size: 1.6rem;
}

.contact_submit{
    width: 100%;
    height: 60px;
    background-color: #F16722;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    border: none;
    transition: .3s;
    font-size: 1.6rem;
}

.contact_submit:hover, .contact_submit:active{
    box-shadow: inset 0 4em 0 0 #F16722;
}





.csms{
    width: 100%;
    height: 100vh;
}

.bordertitle{
    margin-bottom: 15px;
}

.bordertitle span{
    padding: 5px 10px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #163457;
    border: 2px solid #163457;
    border-radius: 100px;
    white-space: nowrap;
}

.csms_1{
    background: url('/public/images/03-01.png') no-repeat center/cover;
    position: relative;
}

.csms_txt_1 div, .csms_txt_1 ul li,
.csms_txt_3 div, .csms_txt_3 ul li,
.csms_4_2 ul li{
    text-align: right;
}

.csms_txt ul li{
    color: #505050;
    font-size: 1.6rem;
}

.csms_title{
    font-weight: 700;
    font-size: 5rem;
    margin-bottom: 4.6vh;
    word-break: keep-all;
}

.add_platform_2_txt .csms_title, .add_platform_6_txt .csms_title{
    text-align: center;
}

.csms_txt_1, .csms_txt_3, .add_platform_1_txt{
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 16.7vw;
    transform: translateY(-50%);
}

.csms_2{
    background: url('/public/images/03-02.png') no-repeat center/cover;
    position: relative;
}

.csms_txt_2, .add_platform_3_txt{
    float: right;
    max-width: 500px;
    position: absolute;
    top: 35%;
    right: 16.7vw;
    transform: translateY(-40%);
}

.csms_3{
    background: url('/public/images/03-04.png') no-repeat 80%/cover;
    position: relative;
    background-color: #f5f5f5;
}

.csms_txt_3 img{
    width: 100%;
    height: auto;
    max-height: 20.6vh;
    max-width: 15vw;
    display: block;
    margin: 0 auto;
}

.csms_4{
    position: relative;
    height: 100vh;
}

.csms_txt_4{
    padding-top: 9.3vh;
    height: 100vh;
}

.csms_txt_4 .csms_title{
    text-align: center;
    margin-bottom: 9.3vh;
}

.csms_4_wrap{
    width: 100%;
    height: 68%;
    position: absolute;
    bottom: 0;
}

.csms_4_1, .csms_4_2{
    height: 50%;
    display: flex;
}

.csms_4_1_img{
    background: url('/public/images/03-05.png') no-repeat center/cover;
    width: 50%;
}

.csms_4_1_list, .csms_4_2_list{
    width: 50%;
    position: relative;
}

.csms_4_1_list ul{
    max-width: 320px;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    word-break: keep-all;
    position: absolute;
}

.csms_4_2_list ul{
    max-width: 600px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    word-break: keep-all;
    position: absolute;
}

.csms_4_1_list ul li{
    text-align: left;
    font-weight: 400;
}

.csms_4_2_list ul li{
    font-weight: 400;
}

.csms_4_1 ul li:first-child, .csms_4_2 ul li:first-child{
    font-weight: 600;
    font-size: 2.4rem;
    color: #101010;
    margin-bottom: 0.9vh;
}

.csms_4_2_img{
    background: url('/public/images/03-06.png') no-repeat bottom/cover;
    width: 50%;
}

.add_platform_1{
    background: #eeeeee url('/public/images/03-07.png') no-repeat;
    background-position: 110% 50%;
    background-size: 60%;
}

.add_platform_2{
    background: url('/public/images/03-08.png') no-repeat center 100%/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add_platform_2_txt, .add_platform_6_txt{
    margin: 0 auto;
    float: none;
    position: static;
    transform: translateY(0%);
}

.add_platform_2_txt ul li, .add_platform_6_txt ul li{
    text-align: center;
}

.add_platform_3_txt ul li:first-child img{
    display: none;
}

.add_platform_3{
    background: url('/public/images/03-09.png') no-repeat;
    background-position: 10% 40%;
    background-size: 50%;
}

.add_platform_4_1_img{
    background: url('/public/images/03-10.png') no-repeat center/60%;
    width: 60%;
}

.add_platform_4_2_img{
    background: url('/public/images/03-11.png') no-repeat center/cover;
    width: 50%;
}

.add_platform_5{
    background: #eeeeee url('/public/images/03-12.png') no-repeat;
    background-position: 0% 50%;
    background-size: 55%;
}

.add_platform_5_txt ul li, .add_platform_6_txt ul li{
    margin-bottom: 15px;
}

.add_platform_6{
    background: url('/public/images/03-13.png') no-repeat 50% 90%/90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add_platform_7{
    background-color: #f5f5f6;
}

.add_platform_7_1_img{
    background: url('/public/images/03-14.png') no-repeat center/cover;
    width: 50%;
}

.add_platform_7_2_img{
    background: url('/public/images/03-15.png') no-repeat center/cover;
    width: 50%;
}

.add_platform_8_1_img{
    background: url('/public/images/03-16.png') no-repeat center/cover;
    width: 50%;
    z-index: -1;
}

.add_platform_8_2_img{
    background: url('/public/images/03-05.png') no-repeat 50% 50%/cover;
    width: 50%;
}



.h3 div{
    font-size: 2.4rem;
}

.charger{
    width: 100%;
    height: 100vh;
    position: relative;
    background: url('/public/images/02-01.png') no-repeat center/cover;
    margin-bottom: 100px;
}

.charger_txt_wrap{
    width: 66.7vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
}

.charger_txt{
    margin-top: 28vh;
    flex-basis: 33.33%;
}

.charger_txt ul li{
    text-align: center;
    color: #fff;
    word-break: keep-all;
}

.charger_txt ul li:first-child{
    font-size: 10rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.2);
    margin-bottom: 50px;
}

.charger_txt ul li:nth-child(2){
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.charger_txt ul li:nth-child(2) span{
    font-size: 2.4rem;
    font-weight: 700;
    color: #F16722;
}

.charger_txt ul li:nth-child(3), .charger_txt ul li:nth-child(4),
.charger_txt ul li:nth-child(5), .charger_txt ul li:nth-child(6){
    font-size: 1.6rem;
}





.KCA7Series h4{
    color: #163457;
}

.KCA7Series .h4::after{
    background-color: #163457;
}

.h4{
    position: relative;
    font-size: 15rem;
    margin-bottom: 128px;
    text-align: center;
}

.h4 div{
    font-size: 5rem;
    color: #aaaaaa;
    text-align: center;
}

.h4::before{
    content: "";
    width: 300px;
    height: 300px;
    background-color: #f5f5f5;
    position: absolute;
    z-index: -50;
    border-radius: 300px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.h4::after{
    content: "";
    width: 1px;
    height: 100px;
    position: absolute;
    z-index: -50;
    top: 132%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}





.KCA7Series_bg{
    width: 100%;
    height: 100vh;
    background: url('/public/images/02-02.png') no-repeat center/cover;
}

.KCA7Series_content_1{
    width: 100%;
    height: 1434px;
    background: url('/public/images/02-03.png') no-repeat center/cover;
    position: relative;
    margin-bottom: 9.3vh;
}

.KCA7Series_stand, .KCA11Series_stand{
    display: flex;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%,-10%);
    -webkit-transform: translate(-50%,-10%);
    transform: translate(-50%,-10%);
    z-index: 50;
}

.KCA7Series_stand div, .KCA11Series_stand div, .KCD35_stand div, .KCD50100_stand div{
    max-width: 100%;
    height: auto;
}

.KCA7Series_stand div img, .KCA11Series_stand div img, .KCD50100_stand div img{
    max-width: 100%;
    height: auto;
}

.KCA7Series_stand_txt, .KCA11Series_stand_txt{
    width: 66.7vw;
    height: 700px;
    position: relative;
    top: 43%;
    left: 50%;
    -ms-transform: translate(-50%,-43%);
    -webkit-transform: translate(-50%,-43%);
    transform: translate(-50%,-43%);
}

.KCA7, .KCA7P, .KCA11, .KCA11P{
    position: absolute;
    width: 23.4vw;
}

.KCA7{
    left: 0;
}

.KCA7 li:first-child, .KCA7P li:first-child,
.KCA11 li:first-child, .KCA11P li:first-child,
.KCD35_nm li:first-child, .KCD50100_nm li:first-child{
    color: #163457;
    font-size: 5rem;
    font-weight: 700;
}

.KCA7 li:nth-child(2), .KCA7P li:nth-child(2),
.KCA11 li:nth-child(2), .KCA11P li:nth-child(2){
    width: 14vw;
    font-size: 2.4rem;
    word-break: keep-all;
}

.KCA7P li:nth-child(2){
    margin-left: 11vw;
    width: 12.37vw;
}

.KCA7 li:nth-child(3), .KCA7P li:nth-child(3),
.KCA11 li:nth-child(3), .KCA11P li:nth-child(3){
    width: 23.4vw;
    height: 1px;
    background-color: #163457;
    margin-top: 20px;
}

.KCA7P{
    right: 0;
}

.KCA7P li{
    text-align: right;
}

.KCA7Series_place, .KCA11Series_place{
    display: flex;
    width: 66.7vw;
    height: auto;
    position: absolute;
    bottom: 50px;
    left: 50%;
    -ms-transform: translate(-50%,-10%);
    -webkit-transform: translate(-50%,-10%);
    transform: translate(-50%,-10%);
    gap: 2.6vw;
}

.KCA7_place, .KCA7P_place, .KCA11_place, .KCA11P_place{
    width: 100%;
}

.KCA7_place img, .KCA7P_place img, .KCA11_place img, .KCA11P_place img{
    max-width: 100%;
    height: auto;
    margin-bottom: 4.6vh;
    box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.2);
}

.KCA7_place ul, .KCA7P_place ul, .KCA11_place ul, .KCA11P_place ul, .KCD35_place ul, .KCD50100_place ul{
    max-width: 450px;
}

.KCA7_place ul li:first-child, .KCA7P_place ul li:first-child,
.KCA11_place ul li:first-child, .KCA11P_place ul li:first-child,
.KCD35_place ul li:first-child, .KCD50100_place ul li:first-child{
    font-size: 2.4rem;
    font-weight: 700;
    word-break: keep-all;
    margin-bottom: 20px;
}

.KCA7_place ul li:first-child span:first-child,
.KCA7P_place ul li:first-child span:first-child,
.KCA11_place ul li:first-child span:first-child,
.KCA11P_place ul li:first-child span:first-child,
.KCD35_place ul li:first-child span:first-child,
.KCD50100_place ul li:first-child span:first-child{
    font-size: 2.4rem;
    font-weight: 700;
    word-break: keep-all;
    color: #F16722;
}

.KCA7_place ul li:first-child span:nth-child(2),
.KCA7P_place ul li:first-child span:nth-child(2),
.KCA11_place ul li:first-child span:nth-child(2),
.KCA11P_place ul li:first-child span:nth-child(2),
.KCD35_place ul li:first-child span:nth-child(2),
.KCD50100_place ul li:first-child span:nth-child(2){
    font-size: 2.4rem;
    font-weight: 700;
    word-break: keep-all;
}

.KCA7_place ul li:nth-child(2), .KCA7_place ul li:nth-child(3),
.KCA7_place ul li:nth-child(4), .KCA7P_place ul li:nth-child(2),
.KCA7P_place ul li:nth-child(3), .KCA7P_place ul li:nth-child(4),
.KCA11_place ul li:nth-child(2), .KCA11_place ul li:nth-child(3),
.KCA11_place ul li:nth-child(4), .KCA11P_place ul li:nth-child(2),
.KCA11P_place ul li:nth-child(3), .KCA11P_place ul li:nth-child(4),
.KCA11P_place ul li:nth-child(5), .KCD35_place ul li:nth-child(2),
.KCD35_place ul li:nth-child(3), .KCD35_place ul li:nth-child(4),
.KCD35_place ul li:nth-child(5), .KCD35_place ul li:nth-child(6),
.KCD50100_place ul li:nth-child(2),
.KCD50100_place ul li:nth-child(3),.KCD50100_place ul li:nth-child(4),
.KCD50100_place ul li:nth-child(5),.KCD50100_place ul li:nth-child(6),
.KCD50100_place ul li:nth-child(7){
    font-size: 1.6rem;
    color: #505050;
    word-break: keep-all;
}





.KCA7Series_content_2, .KCA11Series_content_2{
    width: 66.7vw;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 27.8vh;
}

.content_2_set{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    height: auto;
}

.content_2_set div img{
    max-width: 100%;
    height: auto;
}

.KCA7Series_table, .KCA11Series_table{
    width: 34.9vw;
}

.KCA7Series_table table, .KCA11Series_table table{
    width: 100%;
    border-top: 2px #101010 solid;
}

.KCA7Series_table tr, .KCA11Series_table tr{
    height: 45.11px;
    border-bottom: 0.3px #aaaaaa solid;
}

.KCA7Series_table table tr th, .KCA7Series_table table tr td,
.KCA11Series_table table tr th, .KCA11Series_table table tr td{
    font-size: 1.6rem;
}

.KCA7Series_table table tr th, .KCA11Series_table table tr th{
    text-align: left;
    width: 25%;
    padding-left: 10px;
    background-color: #f5f5f5;
}

.KCA7Series_table table tr td, .KCA11Series_table table tr td{
    text-align: center;
    width: 63%;
}

.KCA7Series_table table tr:first-child td, .KCA11Series_table table tr:first-child td{
    background-color: #aaaaaa;
    color: #fff;
    font-weight: 700;
}

.KCA7Series_table table tr td:nth-child(3) {
    border-left: 1px solid;
}





.KCA11Series h4{
    color: #166363;
}

.KCA11Series .h4::after{
    background-color: #166363;
}

.KCA11Series_bg{
    width: 100%;
    height: 100vh;
    background: url('/public/images/02-08.png') no-repeat center/cover;
}

.KCA11Series_content_1{
    width: 100%;
    height: 1434px;
    background: url('/public/images/02-09.png') no-repeat center/cover;
    position: relative;
    margin-bottom: 9.3vh;
}

.KCA11Series_stand{
    width: 66.7vw;
    justify-content: space-between;
}

.KCA11Series_stand_txt{
    height: 1100px;
}

.KCA11{
    top: 0;
    left: 15%;
    -ms-transform: translate(-15%);
    -webkit-transform: translate(-15%);
    transform: translate(-15%);
}

.KCA11 li{
    text-align: right;
}

.KCA11 li:nth-child(2){
    margin-left: 9.3vw;
    width: 14vw;
}

.KCA11P{
    top: 20%;
    right: 8%;
    -ms-transform: translate(-8%,-20%);
    -webkit-transform: translate(-8%,-20%);
    transform: translate(-8%,-20%);
}

.KCA11P li:nth-child(2){
    width: 12.37vw;
}

.KCA11_place ul, .KCA11P_place ul{
    float: right;
}

.KCA11_place ul li, .KCA11P_place ul li{
    text-align: right;
}

.KCA11 li:first-child, .KCA11P li:first-child{
    color: #166363;
}

.KCA11 li:nth-child(3), .KCA11P li:nth-child(3){
    background-color: #166363;
}

.KCA7P{
    right: 0;
}

.KCA7P li{
    text-align: right;
}

.KCD35 h4{
    color: #0e0457;
}

.KCD35 .h4::after{
    background-color: #0e0457;
}

.KCD35 .h4, .KCD50100 h4{
    position: relative;
    font-size: 15rem;
    margin-bottom: 128px;
    text-align: center;
    line-height: 15rem;
}

.KCD35 .h4::after, .KCD50100 .h4::after{
    content: "";
    width: 1px;
    height: 128px;
    position: absolute;
    z-index: -50;
    top: 121.5%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.KCD35_bg{
    width: 100%;
    height: 100vh;
    background: url('/public/images/02-12.png') no-repeat center/cover;
}

.KCD35_content_1{
    width: 100%;
    height: 1434px;
    background: url('/public/images/02-03.png') no-repeat center/cover;
    position: relative;
    margin-bottom: 9.3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.KCD35_stand, .KCD50100_stand{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    text-align: center;
    margin-top: -90px;
}

.KCD35_stand div img{
    max-width: 66.7%;
    height: auto;
}

.KCD35_stand-txt, .KCD50100_stand_txt{
    width: 66.7vw;
    margin: 0 auto;
}

.KCD35_nm, .KCD50100_nm{
    margin: 0 auto;
    padding-top: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.KCD35_nm li:first-child{
    color: #0e0457;
    border-bottom: 1px solid #0e0457;
}

.KCD35_nm li:last-child, .KCD50100_nm li:last-child{
    font-size: 2.4rem;
    word-break: keep-all;
}

.KCD35_place, .KCD50100_place{
    width: 66.7vw;
    height: auto;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.KCD35_place_inner, .KCD50100_place_inner{
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.KCD35_place img, .KCD50100_place img{
    max-width: 100%;
    height: auto;
    margin-bottom: 4.6vh;
    box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.2);
}

.KCD35_place_inner ul li, .KCD50100_place_inner ul li{
    text-align: center;
}

.KCD50100 h4{
    color: #054361;
}

.KCD50100 .h4::after{
    background-color: #054361;
}

.KCD50100_bg{
    width: 100%;
    height: 100vh;
    background: url('/public/images/02-16.png') no-repeat center/cover;
}

.KCD50100_content_1{
    width: 100%;
    height: 1434px;
    background: url('/public/images/02-09.png') no-repeat center/cover;
    position: relative;
    margin-bottom: 9.3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.KCD50100_stand div img{
    width: 700px;
}

.KCD50100_nm li:first-child{
    color: #054361;
    border-bottom: 1px solid #054361;
}





/* 추가 */

/* .platform .gnb_menu li:nth-child(2) a{color: #F16722; font-weight: 700;}
.platform .hamburger nav ul li:nth-child(2) a{color: #F16722; font-weight: 700;} */
.platform .main_bg{background: url('/public/images/bg-03.png') no-repeat left/cover;}

/* .device .gnb_menu li:first-child a{color: #F16722; font-weight: 700;}
.device .hamburger nav ul li:first-child a{color: #F16722; font-weight: 700;} */
.device .main_bg{background: url('/public/images/bg-02.png') no-repeat center/cover;}
.device .h3 span{color: #F16722; font-size: 5rem;}

/* .procedure .gnb_menu li:nth-child(3) a{color: #F16722; font-weight: 700;}
.procedure .acc_content div:last-child a{color: #F16722; font-weight: 700;} */
.procedure .main_bg{background: url('/public/images/bg-04.png') no-repeat center/cover;}
.procedure .subnav_btn div:last-child a{background-color: #F16722; font-weight: 700;}

/* .inquiry .gnb_menu li:nth-child(3) a{color: #F16722; font-weight: 700;} */
/* .inquiry .acc_content div:first-child a{color: #F16722; font-weight: 700;} */
.inquiry .main_bg{background: url('/public/images/bg-04.png') no-repeat center/cover;}
.inquiry .subnav_btn div:first-child a{background-color: #F16722; font-weight: 700;}

.radio_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.info_popup{
    position: relative;
    width: 33vw;
}
.info_daumPostcode{
    background : rgba(0,0,0,0.25);
    position : absolute;
    right: -32rem;
    top: -12rem;
    z-index: 10;
    border: 5px solid #7b7b7b;
}
.info_close_img {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: -30.3rem;
    top: -11.5rem;
    z-index: 11;
    background-color: #000;
    cursor: pointer;
}


/* 게시판 */
.support {
    width:50vw;
    min-height: 60vh;
    margin:0 auto;
    margin-top: 280px;
    font-size: 100%;
}

.support h2 {
    font-size: 30px;
}

.support table {
    width: 100%;
    text-align: center;
    /* font-size: 38px; */
}

.support p {
    font-size: 16px;
}
.support span {
    font-size: 16px;
}
.support p span {
    font-size: 16px;
    color: #F16722;
}
.support table thead {
    border-top: 2px solid black;
    background-color: #f7f7f7;
}
.support table th {
    font-size: 16px;
}
.support table td {
    font-size: 16px;
}
.support table a {
    font-size: 16px;
}
.support table tr {
    height: 55px;
    border-bottom: 1px solid #e0e0e0;
}

.board {
    width:100%;
    display:flex;
    flex-direction: column;
    border-top: 2px solid black;
}

.board > div {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
}

.board div span {
    font-size: bold;
}



/* animation */
@-webkit-keyframes slide-in-blurred-top {
    0% {
      -webkit-transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
              transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-top {
    0% {
      -webkit-transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
              transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }





  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-moz-keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-webkit-keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-o-keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-ms-keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @keyframes fadeInUp{
    from {
      opacity: 0;
      -webkit-transform:translateY(100px);
      transform:translateY(100px);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }





  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }





  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }





  @-webkit-keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.1) translateY(-15px);
              transform: scale(1.1) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }
  @keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.1) translateY(-15px);
              transform: scale(1.1) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }





  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }



/* responsive */
/* medium */

@media (max-width: 1280px){

    .iconFlexBox {
        position: relative;
        right: 70px;
    }

    .subnav_btn_full div a{
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: normal;
        word-break: keep-all;
        line-height: 20px;
    }


    .radio_wrap label{
        font-size: 1.7rem;
    }


    .gnb_menu{
        display: none;
    }





    .gnb_toggleBtn{
        position: absolute;
        top: 33%;
        right: 0;
        z-index: 9999;
        display: block;
        cursor: pointer;
    }

    .menuToggle + label img:last-child{
        display: none;
    }

    .hamburger{
        display: block;
    }

    .menuToggle:checked ~ nav{
        opacity: 1;
        visibility: visible;
    }

    .menuToggle:checked ~ nav ul{
        top: 70px;
    }

    .menuToggle:checked ~ nav ul li{
        transform: translateY(0px);
        opacity: 1;
    }

    .menuToggle:checked ~ nav ul li:nth-child(1){
        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.1s;
    }

    .menuToggle:checked ~ nav ul li:nth-child(2){
        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.2s;
    }

    .menuToggle:checked + label img:first-child{
        display: none;
    }

    .menuToggle:checked + label img:last-child{
        display: block;
        position: fixed;
        right: 6vw;
    }

    .hamburger nav{
        background-color: rgba(0, 0, 0, 0.95);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    .hamburger nav ul{
        padding: 0;
        margin: 0;
        position: absolute;
        left: 50%;
        top: 170px;
        width: 100%;
        transition: all 0.3s ease-in-out;
        transform: translateX(-50%);
    }

    .hamburger nav ul li{
        transform: translateY(50px);
        opacity: 0;
        position: relative;
        border-bottom: 1px solid #202020;
    }

    .hamburger nav ul li:last-child{
        border-bottom: none;
    }

    .hamburger nav ul li > a{
        display: block;
        font-size: 2rem;
        padding: 20px 0;
        text-align: center;
        color: #fff;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }

    .hamburger nav ul li > a:hover, .hamburger nav ul li > a:focus,
    .acc_label:hover, .acc_content div a:hover, .acc_content div a:focus{
        color: #F16722;
        font-weight: 700;
    }

    .accordion input[type=radio]{
        display: none;
    }

    .menu02{
        display: flex;
        flex-direction: column;
        font-size: 2rem;
    }

    #menuToggle-1 + label, #menuToggle-2 + label,
    #menuToggle-3 + label, #menuToggle-4 + label{
        padding: 10px 0;
        text-align: center;
        color: #fff;
        font-size: 1.6rem;
        font-weight: 400;
    }

    #menuToggle-1 + label:hover, #menuToggle-2 + label:hover,
    #menuToggle-3 + label:hover, #menuToggle-4 + label:hover{
        color: #F16722;
        font-weight: 700;
    }

    #menuToggle-1:checked ~ nav, #menuToggle-2:checked ~ nav,
    #menuToggle-3:checked ~ nav, #menuToggle-4:checked ~ nav{
        display: none;
    }

    #menuToggle-1:checked ~ nav ul li, #menuToggle-2:checked ~ nav ul li,
    #menuToggle-3:checked ~ nav ul li, #menuToggle-4:checked ~ nav ul li{
        transform: translateY(0px);
        display: none;
    }

    .acc_label{
        position: relative;
        cursor: pointer;
        display: block;
        padding: 20px 0;
        text-align: center;
        color: #fff;
        font-weight: 500;
        font-size: 2rem;
    }

    .acc_label > a {
        font-size: inherit;
        color: inherit;
    }

    .acc_label::after{
        display: block;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition:all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .acc_arrow{
        position: absolute;
        top: 21px;
        right: 0;
        opacity: .5;
        width: 9.7vw;
    }

    .acc_arrow img{
        width: 30px;
        height: 30px;
    }

    .acc_content{
        max-height: 0;
        height: 0;
        overflow: hidden;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        -webkit-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out;
    }

    .acc_content div{
        padding: 10px 0;
        text-align: center;
    }

    .acc_content div a{
        color: #fff;
        font-size: 1.6rem;
    }

    .accordion input[type=radio]:checked + label + .acc_content{
        max-height: 1000px;
        height: auto;
    }





    .h1{
        font-size: 5rem;
        text-align: center;
    }

    .h1 span{
        font-size: 5rem;
    }

    .mainBtn_wrap{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
    }

    .mainBtn_wrap a{
        display: flex;
        width: 350px;
        height: 76px;
        background-color: rgb(255, 255, 255, 0.1);
        padding: 20px;
        position: relative;
        top: 0;
        left: 0;
        border-right: none;
        border-bottom: 1px solid #808080;
    }

    .mainBtn_wrap a:last-child{
        border-bottom: none;
    }

    .mainBtn_i{
        position: static;
        /*top: 15px;*/
        /*left: 20px;*/
        width: 36px;
        margin-right: 8px;
    }

    .mainBtn_i_h{
        position: static;
        /*top: 15px;*/
        /*left: 20px;*/
        width: 36px;
        display: none;
        margin-right: 8px;
    }

    .mainBtn_wrap a:hover .mainBtn_i{
        display: none;
    }

    .mainBtn_wrap a:hover .mainBtn_i_h{
        display: block;
    }

    .mainBtn_line2{
        display: flex;
        align-items: center;
        position: relative;
        width: unset;
        top: unset;
        left: unset;
        bottom: unset;
        /*position: absolute;*/
        /*bottom: 10px;*/
        /*left: 20px;*/
        /*width: 310px;*/
    }

    .mainBtn_txt{
        color: #fff;
        font-size: 2rem;
        font-weight: 700;
        white-space: nowrap;
    }

    .mainBtn_line2 img{
        width: 32px;
        margin-top: 4px;
    }

    .mainBtn_wrap a:hover{
        background-color: #F16722;
    }

    .mainBtn_wrap a:hover img{
        -webkit-transition: all .5s ease-in-out;
    }

    .mainBtn_wrap a:hover .mainBtn_ar1{
        transform : translateX(105px);
    }

    .mainBtn_wrap a:hover .mainBtn_ar2{
        transform : translateX(130px);
    }

    .mainBtn_wrap a:hover .mainBtn_ar3{
        transform : translateX(85px);
    }


    .f_1{
        flex-direction: column;
        align-items: flex-start;
    }

    .f_1 div{
        margin-left: 0;
    }

    .tel {
        font-size: 16px !important;
        margin-left: 5px;
    }





    .contact_form_2 > input{
        width: 33vw;
    }

    .contact_form_2{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contact_form_2 div{
        width: 33vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contact_form_2 div input{
        width: 22vw;
    }

    .contact_form_2 div button{
        width: 10vw;
        font-size: 1.2rem;
    }





    .csms_txt_1, .csms_txt_3{
        left: 0;
    }

    .csms_txt_2{
        right: 0;
        max-width: 400px;
    }

    .csms_txt_3 ul{
        margin-right: 100px;
    }

    .csms_txt_1 ul li, .csms_txt_2 ul li,
    .csms_txt_3 ul li, .csms_4_1 ul li,
    .csms_4_2 ul li, .add_platform_1_txt ul li,
    .add_platform_2_txt ul li, .add_platform_3_txt ul li,
    .add_platform_4_txt ul li, .add_platform_5_txt ul li,
    .add_platform_6_txt ul li{
        font-size: 1.2rem;
    }

    .add_platform_1_txt {
        left: revert;
    }

    .add-platform-6{
        background: url('/public/images/03-13.png') no-repeat 50% 95%/75%;
    }




    .KCA7Series_bg{
        width: 100%;
        height: 100vh;
        background: url('/public/images/02-02.png') no-repeat center/cover;
    }

    .KCA7Series_stand div:nth-child(2), .KCA11Series_stand div:nth-child(2){
        text-align: right;
    }

    .KCA7Series_stand{
        width: 70vw;
    }

    .KCA7Series_stand div, .KCA11Series_stand div{
        margin: 0 auto;
        max-width: 70%;
        height: auto;
    }

    .KCA7Series_stand div img, .KCA11Series_stand div img{
        max-width: 70%;
        height: auto;
    }

    .KCA7Series_stand_txt, .KCA11Series_stand_txt{
        height: 150px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 40%;
        left: 50%;
        -ms-transform: translate(-50%,-40%);
        -webkit-transform: translate(-50%,-40%);
        transform: translate(-50%,-40%);
    }

    .KCA7, .KCA7P, .KCA11, .KCA11P{
        position: absolute;
        width: 40%;
    }

    .KCA7{
        left: 7%;
    }

    .KCA7P{
        right: 7%;
    }

    .KCA7 li, .KCA7P li, .KCA11 li, .KCA11P li{
        text-align: center;
    }

    .KCA7 li:nth-child(2), .KCA7P li:nth-child(2),
    .KCA11 li:nth-child(2), .KCA11P li:nth-child(2){
        margin: 0 auto;
        width: 100%;
    }

    .KCA7 li:nth-child(3), .KCA7P li:nth-child(3),
    .KCA11 li:nth-child(3), .KCA11P li:nth-child(3){
        display: none;
    }

    .KCA11Series_bg{
        width: 100%;
        height: 100vh;
        background: url('/public/images/02-08.png') no-repeat right/cover;
    }

    .KCA11{
        top: 20%;
        left: 14%;
        -ms-transform: translate(-14%);
        -webkit-transform: translate(-14%);
        transform: translate(-14%);
    }

    .KCA11P{
        right: 5%;
        -ms-transform: translate(-5%);
        -webkit-transform: translate(-5%);
        transform: translate(-5%);
    }





    .KCA7Series_table table tr th, .KCA11Series_table table tr th{
        text-align: left;
        width: 62%;
        padding-left: 2.5vw;
        background-color: #f5f5f5;
    }

    .KCA7Series_table table tr td, .KCA11Series_table table tr td{
        text-align: center;
        width: 65%;
    }




    .support {
        width:100vw;
    }

}

















































/* small */

@media (max-width: 768px){
    .iconFlexBox {
        position: relative;
        right: 42px;
        bottom:4px;
        margin-right: 8px;
    }

    .iconFlexBox img,
    .iconFlexBox svg {
        width: 20px;
        height: 20px;
    }

    .radio_wrap label{
        font-size: 1.6rem;
    }

    .privacy iframe{
        height:150px;
    }

    .header_in{
        height: 80px;
    }

    .gnb_logo a img{
        width: auto;
        height: 40px;
    }

    .gnb_menu{
        display: none;
    }

    .gnb_menu.active{
        display: flex;
    }

    .gnb_toggleBtn{
        top: 20%;
        right: 0;
    }

    .h1{
        font-size: 2.8rem;
        text-align: center;
    }

    .h1 span{
        font-size: 2.8rem;
    }

    /*.mainBtn_wrap{*/
    /*    position: absolute;*/
    /*    bottom: 20px;*/
    /*    left: 50%;*/
    /*    transform: translate(-50%, 0);*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*}*/

    /*.mainBtn_wrap a{*/
    /*    height: 76px;*/
    /*}*/

    /*.mainBtn_wrap a{*/
    /*    border-right: none;*/
    /*    border-bottom: 1px solid #808080;*/
    /*}*/

    /*.mainBtn_wrap a:last-child{*/
    /*    border-bottom: none;*/
    /*}*/

    .footer{
        flex-direction: column;
        height: 186px;
        text-align: center;
    }

    .f_1{
        flex-direction: column;
        align-items: center;
    }

    .f_1 div{
        margin-left: 0;
    }

    .f_2{
        text-align: center;
    }
    .tel {
        font-size: 16px !important;
        margin-left: 5px;
    }





    .main_bg{
        position: relative;
        height: 500px;
    }

    .h2{
        position: absolute;
        top: 30%;
        left: 50%;
        -ms-transform: translate(-50%,-30%);
        -webkit-transform: translate(-50%,-30%);
        transform: translate(-50%,-30%);
        z-index: 0;
    }

    .h2 div{
        color: #fff;
        font-size: 3rem;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }

    .h2 div:nth-child(2){
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
        word-break: keep-all;
        letter-spacing: 0;
    }





    .scroll_downs{
        display: none;
    }





    .subnav_btn{
        position: absolute;
        top: 533px;
        left: 50%;
        width: 100%;
        height: 65px;
        line-height: 50px;
    }

    .subnav_btn div a{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: normal;
        word-break: keep-all;
        height: 65px;
        line-height: 20px;
        font-size: 1.6rem;
    }

    .subnav_btn div a:hover, .subnav_btn div a:active {
        text-shadow: 0 -1px 1px #fff;
        box-shadow: inset 0 3.5em 0 0 #F16722;
        transition: .3s;
    }

    .subnav_btn_full div a:hover, .subnav_btn_full div a:active {
        text-shadow: 0 -1px 1px #fff;
        box-shadow: inset 0 5em 0 0 #F16722;
        transition: .3s;
    }





    .h3{
        font-size: 3.6rem;
        font-weight: 700;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 50px;
        word-break: keep-all;
    }

    .h3 span{
        font-size: 3.6rem;
    }

    .process_1{
        width: 100%;
        height: 280px;
        background-color: #f8f8f8;
        margin-bottom: 50px;
    }

    .process_1 div{
        margin: 0 auto;
        max-width: 95vw;
        height: 100%;
        background: url('/public/images/04-01.png') no-repeat;
        background-size: 100%;
        background-position: center;
    }

    .process_2{
        margin: 0 auto;
        max-width: 90vw;
        margin-bottom: 100px;
    }

    .process_2 ul li{
        font-size: 1.4rem;
    }





    .top_btn img{
        width: 40px;
        height: 40px;
    }




    .contact_wrap{
        width: 90vw;
        margin: 0 auto;
        margin-bottom: 100px;
    }

    .contact_form input, select{
        width: 43vw;
        height: 40px;
    }

    .contact_submit{
        height: 40px;
        transition: .3s;
    }

    .contact_submit:hover, .contact_submit:active{
        box-shadow: inset 0 3em 0 0 #F16722;
    }

    .contact_form_2 > input{
        width: 43vw;
    }

    .contact_form_2 input{
        height: 40px;
    }

    .contact_form_2{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contact_form_2 div{
        width: 43vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contact_form_2 div input{
        width: 28vw;
    }

    .contact_form_2 div button{
        width: 12vw;
        height: 40px;
    }





    .csms_1{
        background: url('/public/images/03-01.png') no-repeat center/cover;
    }

    .csms_1::before{
        content: "";
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
    }

    .csms_title{
        font-size: 2.8rem;
        word-break: keep-all;
        max-width: 330px;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .csms_txt_1, .csms_txt_2, .csms_txt_3{
        float: none;
        min-width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .csms_txt_1 div, .csms_txt_1 ul li,
    .csms_txt_2 div, .csms_txt_2 ul li,
    .csms_txt_3 div, .csms_txt_3 ul li,
    .add_platform_1_txt div,   .add_platform_1_txt ul li,
    .add_platform_2_txt div, .add_platform_2_txt ul li{
        text-align: center;
    }

    .bordertitle span{
        font-size: 1.2rem;
    }

    .csms_2{
        background: url('/public/images/03-02-s.png') no-repeat center/cover;
    }

    .csms_3{
        background: url('/public/images/03-04-s.png') no-repeat center/cover;
    }

    .csms_txt_3 img{
        width: 200px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .csms_txt_3 ul{
        margin-right: 0;
    }

    .csms_4_wrap{
        height: 80%;
    }

    .csms_txt_4{
        padding-top: 50px;
    }

    .csms_txt_4 .csms_title{
        margin-bottom: 0;
    }

    .csms_4_1_list ul{
        left: 20px;
    }

    .csms_4_2_list ul{
        right: 20px;
    }


    .add_platform_1{
        background: #eeeeee url('/public/images/03-07.png') no-repeat;
        background-position: center;
        background-size: 120%;
    }

    .add_platform_1::before, .add_platform_5::before{
        content: "";
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
    }

    .add_platform_2{
        background: url('/public/images/03-08.png') no-repeat 65% 100%/cover;
    }

    .add_platform_3_txt ul li:first-child img{
        max-width: 550px;
        height: auto;
    }

    .add_platform_3{
        background-image: none;
    }

    .add_platform_3_txt{
        max-width: 100%;
        width: 100%;
        float: none;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .add_platform_3_txt div, .add_platform_3_txt ul li{
        text-align: center;
    }

    .add_platform_3_txt ul li:first-child img{
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 15px;
        padding: 10px;
    }

    .add_platform_5{
        background: #eeeeee url('/public/images/03-12.png') no-repeat;
        background-position: center;
        background-size: cover;
    }

    .add_platform_6{
        background: url('/public/images/03-13.png') no-repeat 50% 85%/cover;
    }

    .add_platform_7_1_img{
        background: url('/public/images/03-14.png') no-repeat center/cover;
    }

    .add_platform_7_2_img{
        background: url('/public/images/03-15.png') no-repeat center/cover;
    }

    .add_platform_8_1_img{
        background: url('/public/images/03-16.png') no-repeat center/cover;
    }

    .add_platform_8_2_img{
        background: url('/public/images/03-05.png') no-repeat 50% 50%/cover;
    }


    .charger_txt_wrap{
        width: 90vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .charger_txt{
        margin-top: 0;
        flex-basis: 27%;
        height: 100%;
    }

    .charger_txt ul li:first-child{
        font-size: 2.4rem;
        margin-bottom: 5px;
    }

    .charger_txt ul li:nth-child(2){
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .charger_txt ul li:nth-child(2) span{
        font-size: 1.6rem;
        font-weight: 700;
        color: #F16722;
    }





    .h4, .KCD35 .h4, .KCD50100 .h4{
        font-size: 10rem;
    }

    .h4::after{
        content: "";
        width: 1px;
        height: 70px;
        position: absolute;
        z-index: -50;
        top: 151%;
        left: 50%;
        -ms-transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }


    .KCD35 .h4, .KCD50100 h4{
        line-height: 12rem;
    }

    .KCD35 .h4::after, .KCD50100 .h4::after{
        content: "";
        width: 1px;
        height: 100px;
        position: absolute;
        z-index: -50;
        top: 133%;
        left: 50%;
        -ms-transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }


    .KCA7Series_bg{
        width: 100%;
        height: 100vh;
        background: url('/public/images/02-02.png') no-repeat center/cover;
    }

    .KCA7Series_stand, .KCA11Series_stand{
        width: 60vw;
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%,-200px);
        -webkit-transform: translate(-50%,-200px);
        transform: translate(-50%,-200px);
        z-index: 50;
        justify-content: space-around;
        align-items: flex-start;
    }

    .KCA7Series_stand div, .KCA11Series_stand div{
        top: 450px;
        width: 100%;
        height: auto;
        position: relative;
    }

    .KCA7Series_stand div img, .KCA11Series_stand div img{
        position: absolute;
        bottom: 0;
        height: auto;
    }

    .KCA7Series_stand div:first-child img, .KCA11Series_stand div:first-child img{
        max-width: 67%;
        left: 0;
    }

    .KCA7Series_stand div:nth-child(2) img, .KCA11Series_stand div:nth-child(2) img{
        max-width: 100%;
        right: 0;
    }

    .KCA7Series_stand_txt, .KCA11Series_stand_txt{
        height: 100px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 16%;
        left: 50%;
        -ms-transform: translate(-50%,-16%);
        -webkit-transform: translate(-50%,-16%);
        transform: translate(-50%,-16%);
    }

    .KCA7, .KCA7P, .KCA11, .KCA11P{
        position: absolute;
        bottom: 0;
        width: 40%;
    }

    .KCA7, .KCA11{
        left: 16%;
        -ms-transform: translate(-16%);
        -webkit-transform: translate(-16%);
        transform: translate(-16%);
    }

    .KCA7P, .KCA11P{
        right: 7.5%;
        -ms-transform: translate(-7.5%);
        -webkit-transform: translate(-7.5%);
        transform: translate(-7.5%);
    }

    .KCA7 li, .KCA7P li, .KCA11 li, .KCA11P li{
        text-align: center;
    }

    .KCA7 li:first-child, .KCA7P li:first-child,
    .KCA11 li:first-child, .KCA11P li:first-child{
        color: #163457;
        font-size: 2.4rem;
        font-weight: 700;
        text-decoration: underline;
    }

    .KCA7 li:nth-child(2), .KCA7P li:nth-child(2),
    .KCA11 li:nth-child(2), .KCA11P li:nth-child(2){
        margin: 0 auto;
        font-size: 1.6rem;
        width: 100%;
    }

    .KCA7 li:nth-child(3), .KCA7P li:nth-child(3),
    .KCA11 li:nth-child(3), .KCA11P li:nth-child(3){
        display: none;
    }

    .KCA7Series_place, .KCA11Series_place{
        flex-direction: column;
        align-items: center;
        width: 66.7vw;
        height: auto;
        position: absolute;
        bottom: -100px;
        left: 50%;
        -ms-transform: translate(-50%,-10%);
        -webkit-transform: translate(-50%,-10%);
        transform: translate(-50%,-10%);
    }

    .KCA7_place, .KCA7P_place, .KCA11_place, .KCA11P_place{
        width: 66.7vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .KCA7_place, .KCA11_place{
        margin-bottom: 4.6vh;
    }

    .KCA7P_place, .KCA11P_place{
        margin-bottom: 0;
    }

    .KCA7_place img, .KCA7P_place img, .KCA11_place img, .KCA11P_place img{
        max-width: 80%;
        margin-bottom: 20px;
    }

    .KCA7_place ul, .KCA7P_place ul, .KCA11_place ul, .KCA11P_place ul{
        max-width: 100%;
    }

    .KCA7_place ul li, .KCA7P_place ul li, .KCA11_place ul li, .KCA11P_place ul li{
        text-align: center;
    }






    .KCA11Series_bg{
        width: 100%;
        height: 100vh;
        background: url('/public/images/02-08.png') no-repeat right/cover;
    }

    .KCA11Series_stand_txt{
        top: 17.5%;
        -ms-transform: translate(-50%,-17.5%);
        -webkit-transform: translate(-50%,-17.5%);
        transform: translate(-50%,-17.5%);
    }





    .KCA7Series_content_2, .KCA11Series_content_2{
        width: 90vw;
        flex-direction: column;
    }

    .content_2_set{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .content_2_set div img{
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .KCA7Series_table, .KCA11Series_table{
        width: 100%;
        margin-bottom: 20px;
    }

    .KCA7Series_table table tr th, .KCA11Series_table table tr th{
        text-align: left;
        width: 40%;
        padding-left: 5px;
        font-size: 1.4rem;
    }

    .KCA7Series_table table tr td, .KCA11Series_table table tr td{
        font-size: 1.4rem;
        width: 60%;
    }

    .KCD35_content_1, .KCD50100_content_1{
        width: 100%;
        height: 1100px;
    }

    .KCD35_nm, .KCD50100_nm{
        margin: 0 auto;
        padding-top: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .KCD35_nm li:first-child, .KCD50100_nm li:first-child{
        text-decoration: underline;
        border-bottom: none;
        font-size: 2.4rem;
        font-weight: 700;
    }

    .KCD35 li:last-child, .KCD50100 li:last-child{
        font-size: 1.6rem;
    }





    .KCD50100-stand div img{
        max-width: 500px;
        height: auto;
    }


}





























@media (max-width: 550px){

    .add_platform_4_1_img{
        background: url('/public/images/03-10.png') no-repeat center/100%;
    }





    .KCD50100_stand div img{
        max-width: 80%;
        height: auto;
    }

    .KCD35_content_1{
        width: 100%;
        height: 900px;
    }









}








@media (max-width: 480px){

    .add_platform_4_1_img{
        background: url('/public/images/03-10.png') no-repeat center/100%;
        width: 45%;
    }

    .add_platform_7_1_img{
        background: url('/public/images/03-14.png') no-repeat 20% center/cover;
    }

    .bordertitle span{
        font-size: 1rem;
    }





    .KCD50100 .h4{
        font-size: 8.5rem;
    }

    .KCD50100-content-1{
        width: 100%;
        height: 1000px;
    }





    .subnav_btn_full div a{
        font-size: 1.2rem;
        line-height: 15px;
    }

    .subnav_btn_full div a:hover, .subnav_btn_full div a:active {
        text-shadow: 0 -1px 1px #fff;
        box-shadow: inset 0 6em 0 0 #F16722;
        transition: .3s;
    }







}





@media (max-width: 340px){

    .add_platform_7_txt ul li, .add_platform_8_txt ul li{
        font-size: 1rem;
    }





    .KCD35 .h4, .KCD50100 .h4{
        font-size: 7.5rem;
    }

    .KCA7Series_content_1, .KCA11Series_content_1{
        width: 100%;
        height: 1200px;
    }

    .KCA7Series_stand_txt, .KCA11Series_stand_txt{
        height: 100px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        top: 22%;
        left: 50%;
        -ms-transform: translate(-50%,-18%);
        -webkit-transform: translate(-50%,-18%);
        transform: translate(-50%,-18%);
        gap: 10px;
    }

    .KCA7, .KCA7P, .KCA11, .KCA11P {
        position: inherit;
        flex-basis: 45%;
    }

    .KCA7, .KCA11{
        left: 11%;
    }

    .KCA7P, .KCA11P{
        right: 0%;
    }

    .KCA7_place ul li:first-child, .KCA7P_place ul li:first-child,
    .KCA11_place ul li:first-child, .KCA11P_place ul li:first-child,
    .KCD35_place ul li:first-child, .KCD50100_place ul li:first-child{
        font-size: 1.6rem;
    }

    .KCA7_place ul li:first-child span:first-child,
    .KCA7P_place ul li:first-child span:first-child,
    .KCA11_place ul li:first-child span:first-child,
    .KCA11P_place ul li:first-child span:first-child,
    .KCD35_place ul li:first-child span:first-child,
    .KCD50100_place ul li:first-child span:first-child{
        font-size: 1.6rem;
    }

    .KCA7_place ul li:first-child span:nth-child(2),
    .KCA7P_place ul li:first-child span:nth-child(2),
    .KCA11_place ul li:first-child span:nth-child(2),
    .KCA11P_place ul li:first-child span:nth-child(2),
    .KCD35_place ul li:first-child span:nth-child(2),
    .KCD50100_place ul li:first-child span:nth-child(2){
        font-size: 1.6rem;
    }

    .KCA7_place ul li:nth-child(2), .KCA7_place ul li:nth-child(3),
    .KCA7_place ul li:nth-child(4), .KCA7P_place ul li:nth-child(2),
    .KCA7P_place ul li:nth-child(3), .KCA7P_place ul li:nth-child(4),
    .KCA11_place ul li:nth-child(2), .KCA11_place ul li:nth-child(3),
    .KCA11_place ul li:nth-child(4), .KCA11P_place ul li:nth-child(2),
    .KCA11P_place ul li:nth-child(3), .KCA11P_place ul li:nth-child(4),
    .KCA11P_place ul li:nth-child(5), .KCD35_place ul li:nth-child(2),
    .KCD35_place ul li:nth-child(3), .KCD35_place ul li:nth-child(4),
    .KCD35_place ul li:nth-child(5), .KCD35_place ul li:nth-child(6),
    .KCD50100_place ul li:nth-child(2),
    .KCD50100_place ul li:nth-child(3),.KCD50100_place ul li:nth-child(4),
    .KCD50100_place ul li:nth-child(5),.KCD50100_place ul li:nth-child(6),
    .KCD50100_place ul li:nth-child(7){
        font-size: 1.4rem;
    }





    .KCA11Series_bg{
        width: 100%;
        height: 100vh;
        background: url('/public/images/02-08.png') no-repeat cover;
        background-position: 95% center;
    }





    .KCD35_stand, .KCD50100_stand{
        margin-top: -30px;
    }

    .KCD35_content_1, .KCD50100_content_1{
        width: 100%;
        height: 850px;
    }
}







/* 300 */

@media (max-width: 300px){

    .h1{
        white-space: normal;
        word-break: keep-all;
    }

    .h1 span{
        white-space: normal;
        word-break: keep-all;
    }


    .mainBtn_wrap{
        bottom: -50px;
        width: 80%;
    }

    .mainBtn_wrap a{
        width: 100%;
    }

    .mainBtn_txt{
        color: #fff;
        font-size: 1.6rem;
        font-weight: 700;
        white-space: nowrap;
    }

    .f_1, .f_2{
        width: 90%;
    }

    .contact_form_c{
        width: 250px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 50px;
    }





    .csms_txt_4{
        padding-top: 20px;
    }

    .csms_4_1, .csms_4_2{
        height: 50%;
        display: flex;
    }

    .csms_4_1 ul li:first-child, .csms_4_2 ul li:first-child{
        font-size: 1.6rem;
        margin-bottom: 10px;
    }

    .add_platform_4_txt ul li{
        font-size: 1rem;
    }





    .h3 div{
        font-size: 1.6rem;
    }



    .charger_txt ul li:nth-child(3), .charger_txt ul li:nth-child(4),
    .charger_txt ul li:nth-child(5), .charger_txt ul li:nth-child(6){
        font-size: 1.4rem;
    }





    .KCA7Series_stand, .KCA11Series_stand{
        width: 80vw;
        -ms-transform: translate(-50%,-300px);
        -webkit-transform: translate(-50%,-300px);
        transform: translate(-50%,-300px);
    }

    .KCA7Series_stand div, .KCA11Series_stand div{
        top: 530px;
    }

    .KCA7Series_stand_txt, .KCA11Series_stand_txt{
        height: 100px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 18%;
        left: 50%;
        -ms-transform: translate(-50%,-18%);
        -webkit-transform: translate(-50%,-18%);
        transform: translate(-50%,-18%);
    }

    /* .KCA7, .KCA11{
        left: 6%;
        -ms-transform: translate(-6%);
        -webkit-transform: translate(-6%);
        transform: translate(-6%);
    }

    .KCA7P, .KCA11P{
        right: 3.5%;
        -ms-transform: translate(-3.5%);
        -webkit-transform: translate(-3.5%);
        transform: translate(-3.5%);
    }

    .KCA7P{
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    } */

    .KCA7_place, .KCA11_place{
        margin-bottom: 100px;
    }

    .KCA7_place img, .KCA7P_place img, .KCA11_place img, .KCA11P_place img, .KCD35_place_inner img, .KCD50100_place_inner img{
        max-width: 100%;
    }

    /* .KCA7_place ul li:first-child, .KCA7P_place ul li:first-child,
    .KCA11_place ul li:first-child, .KCA11P_place ul li:first-child{
        font-size: 1.6rem;
    }

    .KCA7_place ul li:first-child span:first-child,
    .KCA7P_place ul li:first-child span:first-child,
    .KCA11_place ul li:first-child span:first-child,
    .KCA11P_place ul li:first-child span:first-child{
        font-size: 1.6rem;
    }

    .KCA7_place ul li:first-child span:nth-child(2),
    .KCA7P_place ul li:first-child span:nth-child(2),
    .KCA11_place ul li:first-child span:nth-child(2),
    .KCA11P_place ul li:first-child span:nth-child(2){
        font-size: 1.6rem;
    }

    .KCA7_place ul li:nth-child(2), .KCA7_place ul li:nth-child(3),
    .KCA7_place ul li:nth-child(4), .KCA7P_place ul li:nth-child(2),
    .KCA7P_place ul li:nth-child(3), .KCA7P_place ul li:nth-child(4),
    .KCA11_place ul li:nth-child(2), .KCA11_place ul li:nth-child(3),
    .KCA11_place ul li:nth-child(4), .KCA11P_place ul li:nth-child(2),
    .KCA11P_place ul li:nth-child(3), .KCA11P_place ul li:nth-child(4),
    .KCA11P_place ul li:nth-child(5){
        font-size: 1.4rem;
    } */


}

.app_link_section {
    background: #FFFFFF;
    padding: 64px 0;
    text-align: center;
}

.app_link_section > h3 {
    font-size: 36px;
}

.app_link_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    margin: 0 auto;
    gap: 80px;
    padding: 32px 0;
}

.app_link > h4 {
    font-size: 16px;
}

.app_link .app_link_qr {
    display: block;
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    margin: 0 auto;
}

.app_link .app_link_badge {
    display: block;
}

.app_link .app_link_badge_image {
    display: block;
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
}

.header_app_download_icon_container {
    display: contents;
    position: absolute;
}

.header_app_download_icon_container a img {
    max-width: 33px;
    max-height: 33px;
}

.header_app_download_icon_container_outside {
    display: none;
}

@media (max-width: 400px){

    .iconFlexBox a, .iconFlexBox a svg {
        max-width: 24px;
        max-height: 24px;
    }

    .header_app_download_icon_container a img {
        max-width: 24px;
        max-height: 24px;
    }

}

.telMobileLinebreak {
    display: none;
}

@media (max-width: 480px){

    .telMobileLinebreak {
        display: inline;
    }

}
