.container {
	display: contents;
}

.container > section > h4 {
	color: #054361;
	line-height: 0.5;
	& .small {
		color: inherit;
		font-size: 0.5em;
	}
}

.content {
	padding: 60px 0;
	font-size: 1rem;
	background: url('/public/images/02-09.png') no-repeat center/cover;
}

.content * {
	font-size: inherit;
}

.content .mainImages {
	text-align: center;
}

.KCD200240 .content .mainImages img {
	max-width: 40%;
	width: 350px;
}

/*.KCD240 .content .mainImages img {*/
/*	max-width: 90%;*/
/*	width: 800px;*/
/*}*/

.content .titleText {
	padding-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	text-align: center;
	gap: 5px;

	& li:nth-of-type(1) {
		font-size: 50px;
		font-weight: bold;
		color: #054361;
		border-bottom: 1px solid #054361;
	}

	& li:nth-of-type(2) {
		font-size: 2.4rem;
	}
}

.content .description {
	margin: 30px auto 0 auto;
	text-align: center;
	font-size: 2.4rem;
	max-width: 500px;
}

.content .description .name {
	font-weight: bold;
	color: #F16722;
}

.content .features {
	margin-top: 20px;
	text-align: center;
	font-size: 1.6rem;
}

.spec {
	padding: 60px 0;
	font-size: 1rem;
}

.spec * {
	font-size: inherit;
}

.spec > div {
	max-width: 90vw;
	margin: 0 auto;
}

.spec > div + div {
	margin-top: 60px;
}

.KCD240Dispenser {
	font-size: 1rem;
	margin-top: 120px;
}

.KCD240Dispenser .content .mainImages img {
	max-width: 90%;
	width: 800px;
}
